









import { Component, Vue } from 'vue-property-decorator';
import InternSelectionTable from '@/components/tables/InternSelectionTable.vue';

@Component({
  components: { InternSelectionTable },
})
export default class InternSelectionView extends Vue {}
