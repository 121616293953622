import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grey lighten-4 pa-6 mt-3"},[_c('div',{staticClass:"text-h3",domProps:{"textContent":_vm._s(_vm.$t('userSelection.internTable.tableTitle'))}}),_c(VSheet,{staticClass:"ma-6",attrs:{"outlined":"","rounded":""}},[_c(VDataTable,{staticClass:"data-table-pointer",attrs:{"headers":_vm.headers,"disable-sort":true,"items":_vm.interns,"loading":_vm.isInternsLoading,"loading-text":_vm.$t('userSelection.internTable.isLoading'),"hide-default-footer":"","disable-pagination":""},on:{"click:row":_vm.onRowClicked},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-center pa-10 text-h3"},[_vm._v(" "+_vm._s(_vm.$t('userSelection.internTable.noData'))+" ")])]},proxy:true},{key:"item.nameAndItaaNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.firstName) + " " + (item.lastName) + " (" + (item.itaaNr) + ")"))+" ")]}},{key:"item.actions",fn:function(){return [_c(VBtn,{attrs:{"icon":"","elevation":"0"}},[_c(VIcon,[_vm._v("$caret-next")])],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }