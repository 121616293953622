



































import { Component, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { IPerson } from '@/store/modules/user/contracts/person.contract';
import { internModule } from '@/store/modules/intern/intern.module';

@Component({})
export default class internSelectionTable extends Vue {

  public mounted(): void {
    internModule.fetchInterns();
  }

  public get interns(): IPerson[] {
    return internModule.interns.content;
  }

  public onRowClicked(intern: IPerson): void {
    internModule.selectIntern(intern);
    this.$router.push({name: this.Routes.AssignmentOverview})
  }

  public get isInternsLoading(): boolean {
    return !internModule.interns.loaded;
  }

  public get headers(): DataTableHeader[] {
    const properties = ['nameAndItaaNumber'];
    return [
      ...properties.map((prop) => ({
        text: this.$t(`userSelection.internTable.headers.${prop}`).toString(),
        value: prop,
        class: `table__header--${prop}`,
      })),
      {
        text: '',
        value: 'actions',
        width: '4rem',
      },
    ];
  }
}
